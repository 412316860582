import { api_for } from '@/services/helper'
import { DateTime } from 'luxon'
import Storage from '@/lib/storage'
MarketApiV1 = require('@/services/market_api_v1')
market_api = new MarketApiV1.DefaultApi()

store = new Storage({ key: 'suppliers-v1' })

state = {
  current_id: '',
  loading: false,
  all: []
}

getters = {
  list: (state, getters, rootState, rootGetters) ->
    if rootGetters['application/is_agency']
      state.all
    else
      state.all.filter (s) -> !!s.api_endpoint

  current: (state) ->
    s = state.all.find (supplier) -> supplier.id == state.current_id
    s || false

  find: (state) -> (id) ->
    state.all.find (supplier) -> supplier.id == id
}

actions = {
  fetch: ({ commit, rootState }, position) ->
    commit('loading', true)
    api = api_for(rootState.application)
    api.fetchSuppliers({ lat: position?.latitude, lon: position?.longitude })
    .then (suppliers) ->
      if position?.distance
        suppliers.forEach((supplier) ->
          supplier.disabled = Number(supplier.distance) >= position.distance
        )
      commit('set_all', suppliers)
    .catch (error) ->
      console.error 'suppliers.fetch.error:', error
    .finally ->
      commit('loading', false)

  # fetch_agency_suppliers: ({ commit }, options) ->
  #   market_api.fetchAgencySuppliers(options.agency_id)
  #   .then (suppliers) ->
  #     commit('set_all', suppliers)
  #     commit('loading', false)
  #   .catch (error) ->
  #     commit('loading', false)

  select: ({ commit, dispatch, state }, id) ->
    return if state.current_id == id

    commit('current_id', id)
    dispatch('cart/clear', null, { root: true })
    commit('offers/reset', null, { root: true })

  refresh_current: ({commit, dispatch, state, rootState}) ->
    return if !state.current_id

    api = api_for(rootState.application)
    api.fetchSupplier(state.current_id)
    .then (supplier) ->
      if supplier.closed_text
        notice_seen_at = window.localStorage.getItem("supplier_closed_notice_#{supplier.id}")
        today = new DateTime({})
        if notice_seen_at != today.toISODate()
          commit('layout/supplier_closed_modal', true, root: true)
          window.localStorage.setItem("supplier_closed_notice_#{supplier.id}", today.toISODate())
      commit('update', supplier)


}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  current_id: (state, id) ->
    state.current_id = id
    store.write({ current_id: state.current_id, all: state.all })

  restore: (state) ->
    cached = store.read()
    state.all = cached?.all || []
    state.current_id = cached?.current_id

  set_all: (state, suppliers) ->
    state.all = suppliers
    store.write({ current_id: state.current_id, all: state.all })

  update: (state,updated) ->
    current_data = state.all.find (supplier) -> supplier.id == updated.id
    current_data = updated
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
