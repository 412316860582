import { as_json, number_to_currency } from "@/lib/helpers";
import _ from "underscore";
import { DateTime } from "luxon";

const AgencyApiV1 = require("@/services/agency_api");
const agency_api_client = new AgencyApiV1.ApiClient();
const agency_api = new AgencyApiV1.DefaultApi(agency_api_client);

const default_state = () => ({
  client_config: null,
  collection_date: "",
  credit_in_cents: 0,
  items: [],
  loading: false,
  out_of_stock: false,
  total_error: "",
  order_id: null,
  payment_provider: null,
  requires_bags: false,
  requires_shipping: false,
  shipping_provider: null,
  shipping_address: {},
  shipping_cost_in_cents: 0,
  show_outro: false,
  subtotal_in_cents: 0,
  supplier: null,
  total_in_cents: 0,
  transaction: {},
  updated: false,
  validated_at: null,
  discount_code_input: ""
});

const state = default_state();

const getters = {
  has_valid_config: (state, getters, rstate, rgetters) => {
    if (!getters.valid_agency_config) return false;
    if (!rstate.application.features.agency && !getters.valid_market_config)
      return false;
    return true;
  },

  valid_agency_config: (state) => {
    return state.items.length >= 1 && state.supplier != null;
  },

  valid_market_config: (state) => {
    return state.client_config?.shipping?.length > 0;
  },

  supplier: (state) => {
    return state.supplier?.id ? state.supplier : null;
  },

  requires_online_payment: (state) => {
    return ["paygate", "shop2shop", "moya", "yoco"].includes(
      state.payment_provider.toLowerCase(),
    );
  },

  requires_offine_payment: (state) => {
    return ["offline_eft"].includes(state.payment_provider.toLowerCase());
  },

  subtotal_in_cents: (state) => {
    return total_price_in_cents_for(state.items);
  },

  total_in_cents: (state) => {
    return state.total_in_cents;
  },

  formatted_total: (state, getters, rstate, rgetters) => {
    return number_to_currency({
      symbol: rgetters["application/currency"],
      cents: state.total_in_cents,
    });
  },

  is_credit_checkout: (state, getters) => {
    return (
      parseFloat(getters.subtotal_in_cents) <=
      parseFloat(getters.credit_in_cents)
    );
  },

  payment_options: (state, g, rS, rootGetters) => {
    const dispatch = !state.requires_shipping
      ? "COLLECT"
      : state.shipping_provider.toUpperCase();
    if (rootGetters["application/is_agency"]) {
      let options = rootGetters["application/payment_options"](dispatch);
      if (
        options.includes("DEBTORCARD") &&
        !rootGetters["account/find_active_debtorcard"](state.supplier.id)
      ) {
        options = options.filter((opt) => opt !== "DEBTORCARD");
      }
      return options;
    } else {
      const shipping_option = state.client_config.shipping.find(
        (option) => option.name === dispatch,
      );
      return shipping_option.payment_options;
    }
  },

  register_order_payload: (state, getters, rs, rootGetters) => {
    return {
      cart: {
        items: as_json(state.items),
        provider: state.payment_provider,
        supplier_id: state.supplier.id,
        shipping_address: state.shipping_address,
        requires_shipping: state.requires_shipping,
        requires_bags: state.requires_bags,
        shipping_provider: state.shipping_provider,
        collection_date: state.collection_date,
        discount_code: state.discount_code,
        order_id: rootGetters["orders/current_id"],
        shopit: !rootGetters["application/is_agency"],
        is_demo: rootGetters["account/as_json"].demo,
        credit_in_cents: state.credit_in_cents,
        facebook: {
          fbc: document.cookie
            .split("; ")
            .find((row) => row.startsWith("_fbc="))
            ?.split("=")[1],
          fbp: document.cookie
            .split("; ")
            .find((row) => row.startsWith("_fbp="))
            ?.split("=")[1],
        },
      },
      user: rootGetters["account/as_payload"],
    };
  },

  validate_payload: (state, g, rS, rootGetters) => {
    return {
      cart: {
        items: as_json(state.items),
        requires_shipping: state.requires_shipping,
        shipping_provider: state.shipping_provider,
        address_id: state.shipping_address?.id,
        supplier_id: state.supplier.id,
        discount_code: state.discount_code_input,
        collection_date: state.collection_date,
      },
      user: rootGetters["account/as_payload"],
    };
  },

  shipping_options: (state, getters, rstate, rgetters) => {
    if (!rstate.application.features.agency) {
      return state.client_config.shipping.map((shipping) => shipping.name);
    }
    return rstate.application.features.shipping.map(
      (shipping) => shipping.name,
    );
  }
};

const actions = {
  fetch_client_config: ({ commit, state }) => {
    commit("loading", true);
    if (state.supplier.api_endpoint) {
      agency_api.apiClient.basePath = state.supplier.api_endpoint;
      return agency_api.fetchClientConfig(state.supplier.id)
        .then((config) => {
          commit("set_client_config", _.extend({}, config));
        })
        .catch((error) => {
          console.error("fetch_config.catch:", error);
        })
        .finally(() => {
          commit("loading", false);
        });
    } else {
      commit("loading", false);
      console.error("fetch_client_config: api_endpoint undefined");
    }
  },

  validate: ({ commit, state, getters, rootGetters }) => {
    commit("discard_out_of_stock_items");
    commit("loading", true);
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.validate({ body: getters["validate_payload"] })
      .then((response) => {
        commit("reset_errors")
        commit("setup", response.validation);
        commit("validated_at", new Date());
        return response.validation;
      })
      .catch((error) => {
        commit("validated_at", null);
        console.error("agency_api.validate:error", error);
      })
      .finally(() => {
        commit("loading", false);
      })
  },

  register_order: ({ commit, state, getters, rootGetters }, params) => {
    commit("loading", true);
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    const body = getters["register_order_payload"]
    body['cart']['stokvel'] = params.stokvel
    return agency_api.registerOrder({ body: body })
      .then((response) => {
        commit("set_order_id", response.order.id);
        commit("orders/set_current", response.order, { root: true });
        commit("loading", false);
        return response.order;
      })
      .catch((error) => {
        commit("orders/flush_current", null, { root: true });
        commit("loading", false);
        console.error("agency_api.registerOrder.catch:", error);
      });
  },

  register_transaction: ({ commit, rootGetters, state }) => {
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.registerTransaction(state.order_id)
      .then((response) => response)
      .catch((response) => {
        console.error("agency_api.registerTransaction.catch:", response);
      });
  },

  finalize_order: ({ commit, state, rootGetters }) => {
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.prepareOrder(state.order_id)
      .then((response) => {
        commit("loading", false);
      })
      .catch((response) => {
        console.error("agency_api.finalize_order.catch:", response);
        commit("loading", false);
      });
  },

  pending_payment: ({ commit, state, rootGetters }) => {
    new Promise((resolve, reject) => resolve());
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.offlinePayment(state.order_id)
      .then((response) => {
        commit("loading", false);
      })
      .catch((response) => {
        console.error("agency_api.pending_payment.catch:", response);
        commit("loading", false);
      });
  },

  fetch_transaction: ({ state, rootGetters }, params) => {
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.fetchTransaction(params.id)
      .then((response) => response)
      .catch((error) => {
        console.error("agency_api.fetchTransaction catch", error);
      });
  },

  fetch_timeslot_options: ({ commit, state, getters, rootGetters }, params) => {
    agency_api.apiClient.basePath = state.supplier?.api_endpoint || rootGetters["application/api_host"];
    return agency_api.fetchSupplierTimeslots(state.supplier.id, {
        provider: state.provider,
        requires_shipping: state.requires_shipping,
        shipping_provider: state.shipping_provider,
        address_id: state.shipping_address?.id,
        stokvel: params.stokvel,
      })
      .then((response) => response);
  },

  restore: ({ commit }) => {
    const local_checkout = JSON.parse(localStorage.getItem("checkout-v1"));
    if (local_checkout) commit("setup", local_checkout);
  },

  reset: ({ commit, dispatch }) => {
    commit("reset");
    commit("cart/clear", null, { root: true });
  },
};

const mutations = {
  loading: (state, loading) => {
    state.loading = loading;
  },

  reset: (state) => {
    Object.assign(state, _.omit(default_state(), "show_outro"));
    persist(state);
  },

  reset_errors: (state) => {
    state.discount_code_error = null
  },

  set_discount_code: (state, discount_code) => {
    state.discount_code_input = discount_code;
    persist(state);
  },

  setup: (state, comparison) => {
    for (let [k, v] of Object.entries(comparison)) {
      state[k] = v;
    }
    persist(state);
  },

  set_client_config: (state, config) => {
    state.client_config = config;
    persist(state);
  },

  set_requires_shipping: (state, shipping) => {
    state.requires_shipping = shipping;
    state.payment_provider = null;
    if (!shipping) state.shipping_address = {};
    persist(state);
  },

  set_shipping_provider: (state, provider) => {
    state.shipping_provider = provider;
    persist(state);
  },

  set_discount_code_input: (state, string) => {
    state.discount_code_input = string;
    persist(state);
  },

  set_shipping_address: (state, address) => {
    state.shipping_address = address;
    persist(state);
  },

  set_collection_date: (state, datetime) => {
    state.collection_date = datetime;
  },

  set_requires_bags: (state, value) => {
    state.requires_bags = value;
    persist(state);
  },

  set_order_id: (state, uuid) => {
    state.order_id = uuid;
    persist(state);
  },

  set_payment_provider: (state, provider) => {
    state.payment_provider = provider;
  },

  show_outro: (state, boolean) => {
    state.show_outro = boolean;
    persist(state);
  },

  discard_out_of_stock_items: (state) => {
    state.items = state.items.filter((item) => !item.offer.out_of_stock_at);
    persist(state);
  },

  validated_at: (state, timestamp) => {
    state.validated_at = timestamp;
  },
};

const persist = (state) => {
  localStorage.setItem("checkout-v1", JSON.stringify(_.omit(state, "loading")));
};

const total_price_in_cents_for = (items) => {
  return _.reduce(items, sum_of_item_totals, 0);
};

const sum_of_item_totals = (memo, value, index, list) => {
  return memo + parseFloat(value.offer.price_in_cents) * Number(value.qty);
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
