<template>
  <v-dialog v-model="visible" persistent width="500">
    <v-card class="r---modal">
      <div class="d-flex align-center mb-4">
        <div class='r---modal-title'></div>

        <v-btn @click='dismiss' class='r---modal-close ml-auto' style='z-index: 1;'></v-btn>
      </div>
      <div class='text-center my-4'>
        <div class="r---circle r---circle-red mx-auto mb-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
            <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
          </svg>
        </div>

        {{ this.current_supplier && this.current_supplier.closed_text }}
      </div>
      <div class="my-2"></div>

      <v-btn @click='dismiss' class='r---button r---button-primary' block>
        Okay
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    current_supplier() { return this.$store.getters['suppliers/current'] },
    visible() { return !!this.current_supplier && !!this.current_supplier.closed_text && this.$store.state.layout.supplier_closed_modal },
  },

  methods: {
    dismiss() {
      this.$store.commit('layout/supplier_closed_modal', false)
    }
  }
}

</script>
