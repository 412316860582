<template>
  <div>
    <v-card data-cy-offer @click='show_add_to_cart' outlined class="r---offercard-image">

      <div class='flex-grow-1 r---offercard-badge'>
        <div class='position-absolute d-flex flex-column' style='top: 8px; right: 8px; z-index: 1;'>
          <span v-if='offer.promo' class='r---badge r---colors-promotion align-self-end mb-1'>
            {{ $t('offer_card_special') }}
          </span>
          <span v-if='offer.low_stock_label' class='r---badge r---colors-secondary align-self-end'>
            {{ offer.low_stock_label }}
          </span>
        </div>
        <div class='position-absolute d-flex flex-column' style='top: 8px; left: 8px; z-index: 1;'>
          <span v-if='offer.sellable.pack > 1' class='r---badge r---colors-primary align-self-start mb-1'>
            {{ $t('offer_card_pack_size', { size: offer.sellable.pack }) }}
          </span>
          <span v-if='offer.stokvel' class='r---badge r---colors-primary align-self-start'>
            {{ $t('offer_card_stokvel') }}
          </span>
        </div>
      </div>

      <v-img :src='offer.sellable.image_url' :aspect-ratio="1/1">
        <template v-slot:placeholder>
          <LoadingSpinner></LoadingSpinner>
        </template>
      </v-img>

      <div v-if='cart_qty > 0' class='cart-indicator-wrapper r---text-size-0 r---colors-primary px-2 py-1 d-flex'>
        <span>
          <v-icon x-small>fas fa-shopping-cart</v-icon> &times; {{formatted_cart_qty}}
        </span>
        <span class='ml-auto'>
          {{number_to_currency(cart_value)}}
        </span>
      </div>
    </v-card>

    <div @click='show_add_to_cart' class='text-center r---offercard-text'>
      <div class='text-body-2 my-1 r---offercard-name'>{{ offer.sellable.name }}</div>
      <div class='text-body-1 r---offercard-price'>
        <span v-if='!!offer.low_stock_string && !!offer.out_of_stock_at' class='r---text-color-grey'>
          {{offer?.price}}
        </span>
        <span v-else>
          {{ !!offer.out_of_stock_at ? $t('offer_card_out_of_stock') : offer?.price }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/loading_spinner'
import { number_to_currency } from '@/lib/helpers'

export default {
  components: {
    LoadingSpinner
  },

  props: {
    offer: {
      type: Object
    }
  },

  computed: {
    formatted_price() {
      const currency = this.$store.getters['application/currency']
      const unit = this.offer.per_kg ? `/ ${this.offer.per_kg_symbol}` : ''
      return `${currency} ${this.offer.price}${unit}`
    },

    cart_qty() {
      return this.$store.getters['cart/offer_qty'](this.offer.id)
    },

    formatted_cart_qty() {
      return this.$store.getters['cart/formatted_offer_qty'](this.offer.id)
    },

    cart_value() {
      return this.$store.getters['cart/offer_total_in_cents'](this.offer.id)
    }
  },

  methods: {
    number_to_currency(cents) {
      return number_to_currency({
        symbol: this.$store.getters['application/currency'],
        cents: cents
      })
    },

    show_add_to_cart() {
      this.$store.commit('offers/adding_to_cart', this.offer)
    }
  }
}
</script>

<style>
.cart-indicator-wrapper {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 4px !important;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.rounded-bottom {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.v-responsive__content {
  display: flex;
}
</style>
