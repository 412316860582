<template>
  <v-dialog v-model='show' width='500' persistent>

    <v-card color='r---modal'>
      <div class="d-flex r---modal-head">
        <div class='r---modal-title'>{{ !!offer && offer.sellable.name }}</div>

        <v-btn @click='dismiss' class='r---modal-close' style='z-index: 1;'></v-btn>
      </div>

      <div v-if='enlarge_image' style='' @click='toggle_large_image(false)' class='position-relative'>
        <v-img v-if='offer' :src='offer.sellable.image_lg_url' :aspect-ratio="1 / 1">
          <template v-slot:placeholder>
            <LoadingSpinner></LoadingSpinner>
          </template>
        </v-img>
        <div class='position-absolute fa-stack' style='bottom: 4px; left: 4px;'>
          <i class="fas fa-circle fa-stack-2x r---text-white"></i>
          <i class='fas fa-search-minus fa-stack-1x r---text-primary' />
        </div>
      </div>

      <div v-else>
        <div class="r---modal-item">
          <div class='d-flex'>
            <div @click='toggle_large_image(true)'>
              <div class='d-flex r---cart-image-wrapper r---modal-item-image position-relative'>
                <v-img v-if='offer' :src='offer.sellable.image_url' class='rounded r---cart-image'
                  :aspect-ratio="1 / 1">
                  <template v-slot:placeholder>
                    <LoadingSpinner></LoadingSpinner>
                  </template>
                </v-img>
                <div v-if='!!offer && offer.sellable.image_lg_url' class='position-absolute fa-stack'
                  style='bottom: 4px; left: 4px;'>
                  <i class="fas fa-circle fa-stack-2x r---text-white"></i>
                  <i class='fas fa-search-plus fa-stack-1x r---text-primary' />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column r---modal-item-meta">
              <div class='mb-0 r---cart-text-top-wrapper'>
                <span data-cy-unit-price class='r---cart-text-top-name'>
                  {{ $t('add_to_cart_unit_price', { price: offer?.price }) }}
                </span>

                <div v-if='!!offer && offer.promo' class='r---cart-text-top-price'>
                  <div data-cy-promo-end>
                    {{ $t('add_to_cart_valid_until', { date: format_date_less_one(offer.end_at) }) }}
                  </div>
                </div>
              </div>

              <div class="d-flex r---modal-text-bottom-wrapper">
                <p class="r---modal-unit-price">{{ !!offer && offer.supplier.name }}</p>
              </div>
            </div>
          </div>

          <template v-if='!!offer && offer.sellable.combo_items'>
            <ul v-if='offer.sellable.combo_items.length > 0' class='combo-items-list r---text-size-1'>
              <li v-for='combo_item in offer.sellable.combo_items' class='list-item d-flex align-center'>
                <div style='flex-basis: 50px; flex-grow: 0;'>
                  <v-img :src='combo_item.image_url' height=50 width=50 class='mr-1'>
                    <template v-slot:placeholder>
                      <LoadingSpinner></LoadingSpinner>
                    </template>
                  </v-img>
                </div>
                <div class='flex-grow-1'>
                  <div style='max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: wrap;'>
                    {{ combo_item.name }}
                  </div>
                  <div class='text-right'>
                    &times; {{ combo_item.combo_qty }}
                  </div>
                </div>
              </li>
            </ul>
          </template>

          <div v-if='offer' class='d-flex justify-center align-center r---modal-item-qty'>
            <QuantityControl v-model='qty' :per_kg='offer.per_kg' :unit_label='offer.per_kg_symbol'
              :per_kg_conversion_rate='offer.per_kg_conversion_rate' :per_kg_qty_min='offer.per_kg_qty_min'
              :per_kg_qty_step='offer.per_kg_qty_step' />
          </div>

          <v-skeleton-loader v-else width='100' height='30'></v-skeleton-loader>

          <div v-if='!!offer && offer.per_kg' class='mx-auto mt-2' style='max-width: 200px; font-size: 13px;'>
            *measurement may vary up to 5%
          </div>
        </div>

        <div v-if='!!offer && offer.stokvel' class='r---text-size-1 mt-5 d-flex' style='color: var(--v-promotion-base)'>
          Stokvel&nbsp;<span v-if='bulk_pack_savings'>Savings: </span><span class='ml-auto'>{{ bulk_pack_savings }}</span>
        </div>

        <div v-if='!!offer' class='r---modal-total-wrapper'>
          <div class='d-flex'>
            <span>{{ $t('add_to_cart_total') }}: </span>
            <span class="ml-auto r---modal-total-price">{{ add_to_cart_total }}</span>
          </div>
          <div v-if='offer.low_stock_string' class='text-center r---text-size-1 my-2'>
            {{ offer.low_stock_string }}
          </div>
        </div>


        <div>
          <v-btn data-cy-add-to-cart @click='add_to_cart' :loading='loading' block depressed
            class='r---button r---button-primary r---button-cart r---btn-primary-action' :disabled='!!offer && !!offer.out_of_stock_at'>
            <v-icon>fas fa-shopping-cart</v-icon>
            {{ (quantity_in_cart > 0) ? $t('add_to_cart_update_cart') : $t('add_to_cart_add_to_cart') }}
          </v-btn>

          <div v-if='quantity_in_cart > 0 && !this.$store.state.cart.proxy_order_id' class='mt-2'>
            <v-btn v-if='!confirm_removal' @click='confirm_removal = true' block class='r---button r---button-delete r---button-trash'>
              <v-icon small>fas fa-trash</v-icon>
              {{$t('add_to_cart_remove')}}
            </v-btn>

            <div v-if='confirm_removal'>
              <div class='mb-4 text-center r---text-size-1'> Remove product from cart? </div>
              <div class='r---button-group flex-row'>
                <v-btn block class='r---button-small r---button-delete' @click='confirm_removal = false'>
                  No
                </v-btn>
                <v-btn block class='r---button-small r---button-primary' @click='remove_item_from_cart'>
                  Yes
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { as_json, format_date_less_one, number_to_currency } from '@/lib/helpers'
import QuantityControl from '@/components/quantity_control'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    QuantityControl,
    LoadingSpinner
  },

  data() {
    return {
      qty: 0,
      show: false,
      loading: false,
      enlarge_image: false,
      confirm_removal: false
    }
  },

  methods: {
    async add_to_cart() {
      this.loading = true
      if (this.$store.state.cart.proxy_order_id) {
        this.$store.dispatch('orders/add_item', {
          offer: as_json(this.offer),
          qty: as_json(this.qty)
        })
        this.dismiss()
        this.$router.push(`/orders/${this.$store.state.cart.proxy_order_id}`)
        this.$store.commit('cart/stop_order_proxy')
      } else {
        await this.$store.dispatch('cart/add_item', {
          offer: as_json(this.offer),
          qty: as_json(this.qty)
        })
        this.track_add_to_cart()
        this.dismiss()
      }
    },

    remove_item_from_cart() {
      this.$store.dispatch("cart/remove_item", this.offer.id)
      this.dismiss()
    },

    dismiss() {
      this.$store.commit('offers/set_current', null)
      this.qty = -1
      this.loading = false
      this.confirm_removal = false
    },

    format_date_less_one(datetime) {
      return format_date_less_one(datetime)
    },

    number_to_currency(cents) {
      return number_to_currency({
        cents: cents,
        symbol: this.$store.getters['application/currency']
      })
    },

    build_default_qty() {
      this.qty = this.$store.state.cart.proxy_order_id
        ? this.$store.getters['orders/offer_qty'](this.offer.id) || 0
        : this.$store.getters['cart/offer_qty'](this.offer.id) || 0
    },

    track_item_select() {
      const event_payload = this.$store.getters['analytics/build_select_item_payload'](this.offer)
      this.$store.dispatch('analytics/select_item', event_payload)
    },

    track_add_to_cart() {
      const event_payload = this.$store.getters['analytics/build_select_item_payload'](this.offer, this.qty)
      this.$store.dispatch('analytics/add_to_cart', event_payload)
    },

    toggle_large_image(show) {
      if (!(this.offer && this.offer.sellable.image_lg_url)) return
      this.enlarge_image = show
    }
  },

  computed: {
    formatted_unit_price() {
      if (!this.offer) return ''

      const text = `${this.$store.getters['application/currency']} ${this.offer.price}`
      return this.offer.per_kg ? `${text} /${this.offer.per_kg_symbol}` : text
    },

    bulk_pack_savings() {
      if (!this.offer) return null

      const cost_at_base_price = this.offer.base_price_in_cents * Number(this.offer.sellable.pack)
      const savings_in_cents = cost_at_base_price - this.offer.price_in_cents

      if (savings_in_cents > 0) {
        return this.number_to_currency(savings_in_cents * this.qty)
      }
      return null
    },

    offer() {
      console.log(JSON.parse(JSON.stringify(this.$store.state.offers.current)))
      return this.$store.state.offers.current
    },

    quantity_in_cart() {
      return this.$store.getters['cart/offer_qty'](this.offer?.id)
    },

    unit_label() {
      return this.offer && this.offer.per_kg ? 'kg' : ''
    },

    add_to_cart_total() {
      if (!this.offer) return ''
      return this.number_to_currency(this.qty * Number(this.offer.price_in_cents))
    }
  },

  watch: {
    offer(offer) {
      this.enlarge_image = false
      if (offer) {
        this.build_default_qty()
        this.track_item_select()
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.r---modal {
  @media only screen and (max-width: 359px) {
    padding: 12px;
  }
}

.r---modal-head {
  @media only screen and (max-width: 359px) {
    padding-bottom: 12px !important;
  }
}

.combo-items-list {
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
  color: #6b7280;
  max-height: 200px;
  overflow: scroll;
}

.combo-items-list .list-item {
  border-bottom: 1px solid #e5e7eb;
  padding-top: 4px;
}

.combo-items-list .list-item:first-child {
  border-top: 1px solid #e5e7eb;
}
</style>
